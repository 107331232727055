export const stringToLetters = (text) => {
	let wordLength = text.split(' ');

	return wordLength.map((i, k) => {
		return (
			<span
				key={k}
				className='word'>
				{i}
			</span>
		);
	});
};
