import { createGlobalStyle, css } from 'styled-components';
import localFont from 'next/font/local';
import theme from './theme';

const montserrat = localFont({
	src: [
		{
			path: 'fonts/montserrat-regular.woff',
			weight: '400',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/montserrat-medium.woff',
			weight: '500',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/montserrat-semiBold.woff',
			weight: '600',
			display: 'swap',
			style: 'normal',
		},
	],
});

function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

function colorVariablesGenerator() {
	const colorsArr = Object.keys(theme.colors);

	let colorVariable = '';

	for (let i = 0; i < colorsArr.length; i++) {
		colorVariable += `--${colorsArr[i]}: ${theme.colors[colorsArr[i]]};`;
	}

	return colorVariable;
}

const Variables = createGlobalStyle`${css`
	:root {
		//! Screen Sizes
		--desktopSizeXL: ${(props) => props.theme.mediaQuery.DesktopSizeXL};
		--desktopSizeL: ${(props) => props.theme.mediaQuery.DesktopSizeL};
		--desktopSizeM: ${(props) => props.theme.mediaQuery.DesktopSizeM};
		--desktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
		--desktopSizeXS: ${(props) => props.theme.mediaQuery.DesktopSizeXS};
		--tabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
		--tabletSizeS: ${(props) => props.theme.mediaQuery.TabletSizeS};
		--mobileSize: ${(props) => props.theme.mediaQuery.MobileSize};
		--mobileSizeS: ${(props) => props.theme.mediaQuery.MobileSizeS};

		//! Fonts
		--montserrat: ${montserrat.style.fontFamily};

		//! Spacing
		--spSize: 8px;

		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp2-5x: calc(var(--spSize) * 2.5);
		${spaceGenerator()}

		//! Transition
        --trTime: 0.3s;

		//! Line heights
		--lineHeight1: 1.6;
		--lineHeight2: 1.4;
		--lineHeight3: 1.2;

		//! Colors
		${colorVariablesGenerator()};
	}
	//! 2560
	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
		:root {
			--h1: 104px;
			--h2: 80px;
			--h3: 56px;
			--h4: 48px;
			--h5: 32px;
			--h6: 24px;
			--p1: 18px;
			--p2: 16px;

			//! Section distance
			--sectionDistance: var(--sp30x);
			--titleToContent: var(--sp12x);
			--subtitleToContent: var(--sp7x);
			--headerToContent: var(--sp11x);
			--compasIconSizes: var(--sp19x);
			--arrowLongIconSizes: var(--sp5x);
			--locationIconSizes: var(--sp8x);
			--crossIconSizes: var(--sp3x);
			--headerFixedHeight: var(--sp23x);

			//! Grid distance
			--colPadding: var(--sp1-5x);

			//! Container padding
			--contPaddingLR: var(--sp12x);
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		:root {
			--h1: 88px;
			--h2: 64px;
			--h3: 40px;
			--h4: 32px;
			--h5: 24px;
			--h6: 18px;
			--p1: 16px;
			--p2: 14px;

			//! Section distance
			--sectionDistance: var(--sp25x);
			--titleToContent: var(--sp10x);
			--subtitleToContent: var(--sp5x);
			--headerToContent: var(--sp7x);
			--compasIconSizes: var(--sp14x);
			--arrowLongIconSizes: var(--sp4x);
			--locationIconSizes: var(--sp7x);
			--crossIconSizes: var(--sp3x);
			--headerFixedHeight: calc(var(--sp16x) + var(--sp0-5x));

			//! Grid distance
			--colPadding: var(--sp1x);

			//! Container padding
			--contPaddingLR: var(--sp10x);
		}
	}

	//! 1510
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		:root {
			--h1: 64px;
			--h2: 48px;
			--h3: 32px;
			--h4: 24px;
			--h5: 20px;
			--h6: 16px;
			--p1: 14px;
			--p2: 14px;

			//! Section distance
			--sectionDistance: var(--sp20x);
			--titleToContent: var(--sp8x);
			--subtitleToContent: var(--sp5x);
			--headerToContent: var(--sp5x);
			--compasIconSizes: var(--sp11x);
			--arrowLongIconSizes: var(--sp3x);
			--locationIconSizes: var(--sp6x);
			--crossIconSizes: var(--sp2x);
			--headerFixedHeight: calc(var(--sp14x) + var(--sp0-5x) / 2);

			//! Grid distance
			--colPadding: var(--sp1x);

			//! Container padding
			--contPaddingLR: var(--sp8x);
		}
	}

	//! 1440
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		:root {
			--h1: 64px;
			--h2: 48px;
			--h3: 32px;
			--h4: 24px;
			--h5: 20px;
			--h6: 16px;
			--p1: 14px;
			--p2: 14px;

			//! Section distance
			--sectionDistance: var(--sp20x);
			--titleToContent: var(--sp8x);
			--subtitleToContent: var(--sp5x);
			--headerToContent: var(--sp5x);
			--compasIconSizes: var(--sp11x);
			--arrowLongIconSizes: var(--sp3x);
			--locationIconSizes: var(--sp6x);
			--crossIconSizes: var(--sp2x);
			--headerFixedHeight: calc(var(--sp13x) + var(--sp0-5x) / 2);

			//! Grid distance
			--colPadding: var(--sp1x);

			//! Container padding
			--contPaddingLR: var(--sp7x);
		}
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		:root {
			--h1: 48px;
			--h2: 40px;
			--h3: 24px;
			--h4: 20px;
			--h5: 18px;
			--h6: 16px;
			--p1: 14px;
			--p2: 12px;

			//! Section distance
			--sectionDistance: var(--sp15x);
			--titleToContent: var(--sp6x);
			--subtitleToContent: var(--sp4x);
			--headerToContent: var(--sp5x);
			--compasIconSizes: var(--sp8x);
			--arrowLongIconSizes: var(--sp3x);
			--locationIconSizes: var(--sp6x);
			--crossIconSizes: var(--sp2x);
			--headerFixedHeight: calc(var(--sp12x) + var(--sp0-5x) / 2);

			//! Grid distance
			--colPadding: var(--sp1x);

			//! Container padding
			--contPaddingLR: var(--sp6x);
		}
	}

	//!1024
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		:root {
			--h1: 40px;
			--h2: 32px;
			--h3: 20px;
			--h4: 18px;
			--h5: 16px;
			--h6: 14px;
			--p1: 14px;
			--p2: 12px;

			//! Section distance
			--sectionDistance: var(--sp12x);
			--titleToContent: var(--sp5x);
			--subtitleToContent: var(--sp4x);
			--headerToContent: var(--sp4x);
			--compasIconSizes: var(--sp8x);
			--arrowLongIconSizes: var(--sp2x);
			--locationIconSizes: var(--sp5x);
			--crossIconSizes: var(--sp2x);
			--headerFixedHeight: calc(var(--sp12x));

			//! Grid distance
			--colPadding: var(--sp1x);

			//! Container padding
			--contPaddingLR: var(--sp6x);
		}
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		:root {
			--h1: 40px;
			--h2: 32px;
			--h3: 20px;
			--h4: 18px;
			--h5: 16px;
			--h6: 14px;
			--p1: 14px;
			--p2: 12px;

			//! Section distance
			--sectionDistance: var(--sp12x);
			--titleToContent: var(--sp5x);
			--subtitleToContent: var(--sp4x);
			--headerToContent: var(--sp4x);
			--compasIconSizes: var(--sp8x);
			--arrowLongIconSizes: var(--sp2x);
			--locationIconSizes: var(--sp5x);
			--crossIconSizes: var(--sp2x);
			--headerFixedHeight: calc(var(--sp12x));

			//! Grid distance
			--colPadding: var(--sp1x);

			//! Container padding
			--contPaddingLR: var(--sp4x);
		}
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		:root {
			--h1: 32px;
			--h2: 24px;
			--h3: 20px;
			--h4: 16px;
			--h5: 16px;
			--h6: 14px;
			--p1: 12px;
			--p2: 12px;

			//! Section distance
			--sectionDistance: var(--sp9x);
			--titleToContent: var(--sp3x);
			--subtitleToContent: var(--sp3x);
			--headerToContent: var(--sp3x);
			--compasIconSizes: var(--sp8x);
			--arrowLongIconSizes: var(--sp2x);
			--locationIconSizes: var(--sp4x);
			--crossIconSizes: var(--sp2x);
			--headerFixedHeight: calc(var(--sp11x));

			//! Grid distance
			--colPadding: var(--sp1x);

			//! Container padding
			--contPaddingLR: var(--sp3x);
		}
	}
`}`;

export default Variables;
