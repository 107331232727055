import { createGlobalStyle, css } from 'styled-components';

const HelperClass = createGlobalStyle`${css`
	//! Colors
	.background {
		color: var(--background);

		&-bg {
			background-color: var(--background);
		}
	}

	.opacity- {
		&0 {
			opacity: 0;
		}
	}
	.translate-y- {
		&100 {
			transform: translateY(100%);
		}
	}

	.white {
		color: var(--white);

		&-bg {
			background-color: var(--white);
		}
	}

	.main-green {
		color: var(--green800);

		&-bg {
			background-color: var(--green800);
		}
	}

	.dark-green {
		color: var(--green1000);

		&-bg {
			background-color: var(--green1000);
		}
	}

	.error-color {
		color: var(--errorColor);

		&-bg {
			background-color: var(--errorColor);
		}
	}

	.red {
		color: var(--red900);

		&-bg {
			background-color: var(--red900);
		}
	}

	.main-red {
		color: var(--red1000);

		&-bg {
			background-color: var(--red1000);
		}
	}

	//! Font sizes
	h1,
	.h1 {
		font-size: var(--h1);
		line-height: var(--lineHeight3);
		margin: 0;
	}

	h2,
	.h2 {
		font-size: var(--h2);
		line-height: var(--lineHeight3);
		margin: 0;
	}

	h3,
	.h3 {
		font-size: var(--h3);
		line-height: var(--lineHeight3);
		margin: 0;
	}

	h4,
	.h4 {
		font-size: var(--h4);
		line-height: var(--lineHeight2);
		margin: 0;
	}

	h5,
	.h5 {
		font-size: var(--h5);
		line-height: var(--lineHeight2);
		margin: 0;
	}

	h6,
	.h6 {
		font-size: var(--h6);
		line-height: var(--lineHeight1);
		margin: 0;
	}

	p,
	.p {
		font-size: var(--p1);
		line-height: var(--lineHeight1);

		&-2 {
			font-size: var(--p2);
			line-height: var(--lineHeight1);
		}
	}

	//! Font families
	.font-montserrat {
		font-family: var(--montserrat);
		font-weight: 400 !important;

		&-medium {
			font-family: var(--montserrat);
			font-weight: 500 !important;
		}

		&-semibold {
			font-family: var(--montserrat);
			font-weight: 600 !important;
		}
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.compas-icon {
		font-size: var(--compasIconSizes);
	}

	.arrow-long-icon {
		font-size: var(--arrowLongIconSizes);
	}

	.location-icon {
		font-size: var(--locationIconSizes);
	}

	.cross-icon {
		font-size: var(--crossIconSizes);
	}

	.uppercase {
		text-transform: uppercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.first-latter {
		&:first-letter {
			text-transform: uppercase;
		}
	}
`}`;

export default HelperClass;
