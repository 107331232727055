export default {
	routes: {
		home: {
			path: '/',
			slug: 'home',
			name: 'home',
		},
		buildings: {
			path: '/buildings',
			slug: 'buildings',
			name: 'buildings',
		},
		facilities: {
			path: '/facilities',
			slug: 'facilities',
			name: 'facilities',
		},
		apartmentsIndividual: {
			path: '/apartments/:slug',
			slug: 'apartments',
			name: 'apartments',
			singleType: 'apartment',
		},
	},
};
