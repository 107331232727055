import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';
	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;
		style += `{ width: ${(100 / 12) * i}% } `;
	}

	return style;
}

const Typography = createGlobalStyle`${css`
	* {
		box-sizing: border-box;
		outline: none;
		margin: 0;
		padding: 0;
		-webkit-appearance: none;
		-webkit-text-size-adjust: 100%;
		-webkit-font-smoothing: antialiased;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; //! Touch highlight color
	}
	body {
		width: 100%;
		min-height: 100vh;
		font-family: var(--montserrat);
		background-color: var(--background);
		color: var(--black1000);
		overflow-x: hidden;
		position: relative;
		overflow: ${(props) => (props.popupIsOpen ? 'hidden' : '')};

		header,
		footer {
			filter: blur(0px);
			/* filter: ${(props) => (props.popupIsOpen ? 'blur(2px)' : 'blur(0px)')}; */
			/* animation: ${(props) => (props.popupIsOpen ? `blurBackegraund` : `blurBackegraund reverse`)} var(--trTime) ease-out forwards; */
			animation: ${(props) => (props.popupIsOpen ? `blurBackegraund` : `blurBackegraundReverse`)} var(--trTime) ease-out forwards;
		}

		@keyframes blurBackegraund {
			from {
				filter: blur(0px);
			}
			to {
				filter: blur(2px);
			}
		}

		@keyframes blurBackegraundReverse {
			from {
				filter: blur(2px);
			}
			to {
				filter: blur(0px);
			}
		}

		main {
			filter: blur(0px);
			position: relative;
			display: flex;
			flex-direction: column;
			min-height: 100vh;
			z-index: 1;
			transition: filter var(--trTime) margin-top 1s;
			/* filter: ${(props) => (props.isBurgerMenuOpen || props.popupIsOpen ? 'blur(5px)' : 'blur(0px)')}; */
			/* animation: ${(props) => (props.isBurgerMenuOpen || props.popupIsOpen ? `blurBackegraund` : `blurBackegraund reverse`)} var(--trTime) ease-out forwards; */
			animation: ${(props) => (props.isBurgerMenuOpen || props.popupIsOpen ? `blurBackegraund` : `blurBackegraundReverse`)} var(--trTime) ease-out forwards;
		}

		/* &::-webkit-scrollbar {
			display: none;
		} */

		&.hidden {
			overflow: hidden;
			max-height: 100vh;
		}
	}

	.ant-slider-tooltip {
		display: none;
	}

	ul,
	fieldset {
		margin: 0;
		padding: 0;
		list-style: none;
		border: none;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-block-start: 0;
		margin-block-end: 0;
	}
	a {
		color: unset;
		text-decoration: none;
	}

	button {
		border-width: 0;
		border-color: inherit;
		background-color: inherit;
	}

	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input:-internal-autofill-selected {
		background-color: transparent !important;
		box-shadow: inset 1000px 1000px var(--primaryColor1);
	}

	input:focus::placeholder {
		color: transparent;
	}

	input {
		outline: none;
	}

	input:-webkit-autofill {
		-webkit-text-fill-color: var(--primaryColor1) !important;
		box-shadow: inset 0 0 0 1000px var(--backgroundColor) !important;
	}

	//! Change the transparent to any color
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: 1px solid var(--primaryColor1);
		-webkit-text-fill-color: var(--primaryColor1);
		color: var(--primaryColor1);
		background-color: var(--backgroundColor);
		box-shadow: 0 0 0 1000px var(--backgroundColor) inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	@keyframes autofill {
		to {
			background: var(--backgroundColor);
		}
	}

	a {
		text-decoration: none;
		color: unset;
		-webkit-tap-highlight-color: transparent;
	}

	//! Global Error wrapper
	.globalErrorWrap {
		border: 2.5px solid var(--errorColor);
		border-radius: var(--sp1-5x);
		background-color: rgba(222, 85, 78, 0.05);
		padding: var(--globalErrorPad);
		margin-top: var(--globalErrorWrapMarTB);
		margin-bottom: var(--globalErrorWrapMarTB);

		.comp-title {
			&:last-child {
				margin-top: var(--errorItemMTop);
			}
		}
	}

	//! Row
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--colPadding) * -1);

		> * {
			width: 100%;
			padding: 0 var(--colPadding);
		}

		.col {
			flex: 1 1;
		}

		${columnGenerator()}

		/* //! 1920 */
        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
			${columnGenerator('dl')}
		}

		/* //! 1510 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
			${columnGenerator('dm')}
		}

		/* //! 1440 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
			${columnGenerator('ds')}
		}

		/* //! 1280 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
			${columnGenerator('dxs')}
		}

		/* //! 1024 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
			${columnGenerator('t')}
		}

		//! 768
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
			${columnGenerator('ts')}
		}

		/* //! 414 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
			${columnGenerator('m')}
		}
	}

	@keyframes loading {
		0%,
		100% {
			opacity: 0.7;
		}
		50% {
			opacity: 0.5;
		}
	}
`}`;

export default Typography;
